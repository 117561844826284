import React, { useState, useEffect } from "react";
import { Card, Col, Container, Form, Row } from "react-bootstrap";
import { SHA256 } from "../../utils";

const Hash: React.FC = () => {
  const [hashData, setHashData] = useState("");
  const [hashText, setHashText] = useState("");

  useEffect(() => {
    setHashText(SHA256(hashData));
  }, [hashData]);

  const handleChange = (event: any) => {
    const value = event && event.target ? event.target.value : event;
    setHashData(value);
    setHashText(SHA256(value));
  };

  return (
    <Container>
      <Row>
        <Col xl={12}>
          <h1>SHA256 Hash</h1>
          <Card>
            <Card.Body>
              <Form>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalData"
                >
                  <Form.Label column sm={2}>
                    Data
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="textarea"
                      rows={10}
                      value={hashData}
                      onChange={handleChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalHash"
                >
                  <Form.Label column sm={2}>
                    Hash
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      placeholder="Hash"
                      value={hashText}
                      disabled
                    />
                  </Col>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Hash;
