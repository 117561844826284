import React from "react";
import NavBarHeader from "./components/NavBarHeader/NavBarHeader";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import "./App.scss";
import Hash from "./pages/Hash/Hash";
import BlockPage from "./pages/Block/Block";
import Blockchain from "./pages/Blockchain/Blockchain";
import Distributed from "./pages/Distributed/Distributed";
import Tokens from "./pages/Tokens/Tokens";
import Coinbase from "./pages/Coinbase/Coinbase";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
library.add(faSpinner);

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <NavBarHeader />
        <Switch>
          <Route exact path="/" component={Hash} />
          <Route path="/hash" component={Hash} />
          <Route path="/block" component={BlockPage} />
          <Route path="/blockchain" component={Blockchain} />
          <Route path="/distributed" component={Distributed} />
          <Route path="/tokens" component={Tokens} />
          <Route path="/coinbase" component={Coinbase} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
