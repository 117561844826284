import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { cardClass } from "../../utils";

interface Props {
  txs?: any[];
  block: any;
  chain: number;
  index: number;
  nonce: any;
  coinbasevalue: any;
  coinbaseto: any;
  previous: any;
  hashText: any;
  isSubmitting: boolean;
  handleDataChange: (e: any) => void;
  handleTXChange: (e: any, chain: number, index: number, txIdx: number) => void;
  onSubmit: (e: any) => void;
}

const CoinbaseBlock: React.FC<Props> = ({
  txs,
  block,
  nonce,
  previous,
  hashText,
  coinbasevalue,
  coinbaseto,
  chain,
  index,
  isSubmitting,
  handleDataChange,
  handleTXChange,
  onSubmit,
}) => {
  return (
    <Card className={cardClass(hashText)}>
      <Card.Body>
        <Form>
          <Form.Group as={Row} controlId="formHorizontalBlock">
            <Form.Label column sm={2}>
              Block
            </Form.Label>
            <Col sm={10}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">#</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  aria-describedby="basic-addon1"
                  name="block"
                  value={block}
                  onChange={handleDataChange}
                />
              </InputGroup>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formHorizontalNonce">
            <Form.Label column sm={2}>
              Nonce
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="nonce"
                value={nonce}
                onChange={handleDataChange}
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalCoinbase"
          >
            <Form.Label column sm={2}>
              Coinbase
            </Form.Label>
            <Col sm={10}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon">$</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  aria-describedby="basic-addon1"
                  name="coinbasevalue"
                  value={coinbasevalue}
                  onChange={handleDataChange}
                />
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon">-&gt;</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  aria-describedby="basic-addon1"
                  name="coinbaseto"
                  value={coinbaseto}
                  onChange={handleDataChange}
                />
              </InputGroup>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formHorizontalTx">
            <Form.Label column sm={2}>
              Tx
            </Form.Label>
            <Col sm={10}>
              {txs?.map((tx, idx) => (
                <InputGroup key={idx}>
                  <InputGroup.Prepend>
                    <InputGroup.Text id={`basic-addon${idx}`}>
                      $
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    aria-describedby="basic-addon1"
                    name="value"
                    value={tx.value}
                    onChange={(e) => handleTXChange(e, chain, index, idx)}
                  />
                  <InputGroup.Prepend>
                    <InputGroup.Text id={`basic-addon${idx}`}>
                      From
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    aria-describedby="basic-addon1"
                    name="from"
                    value={tx.from}
                    onChange={(e) => handleTXChange(e, chain, index, idx)}
                  />
                  <InputGroup.Prepend>
                    <InputGroup.Text id={`basic-addon${idx}`}>
                      -&gt;
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl
                    aria-describedby="basic-addon1"
                    name="to"
                    value={tx.to}
                    onChange={(e) => handleTXChange(e, chain, index, idx)}
                  />
                </InputGroup>
              ))}
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formHorizontalPrev">
            <Form.Label column sm={2}>
              Prev
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="Prev"
                value={previous}
                disabled
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formHorizontalHash">
            <Form.Label column sm={2}>
              Hash
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="Hash"
                value={hashText}
                disabled
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}></Form.Label>
            <Col sm={10}>
              <Button variant="primary" onClick={onSubmit}>
                Mine
                {isSubmitting && (
                  <FontAwesomeIcon icon="spinner" spin className="ml-1" />
                )}
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default CoinbaseBlock;
