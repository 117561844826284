import sha256 from "crypto-js/sha256";

export const difficulty = 4; // number of zeros required at front of hash
export const maximumNonce = 500000; // limit the nonce to this so we don't mine too long

export const SHA256 = (value: string) => {
  return sha256(value).toString();
};

export const difficultyPattern = () => {
  // NOTE: Because there are 16 possible characters in a hex value, each time you increment
  // the difficulty by one you make the puzzle 16 times harder. In my testing, a difficulty
  // of 6 requires a maximumNonce well over 500,000,000.

  /////////////////////////
  // global variable setup
  /////////////////////////
  let pattern = "";
  for (var x = 0; x < difficulty; x++) {
    pattern += "0";
  }
  return pattern;
};

export const cardClass = (hashText: string) => {
  if (hashText.substr(0, difficulty) === difficultyPattern()) {
    return "well-success";
  } else {
    return "well-error";
  }
};

