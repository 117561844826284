import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { cardClass } from "../../utils";

interface Props {
  block: any;
  chain: any;
  nonce: any;
  previous: any;
  data: any;
  hashText: any;
  isSubmitting: boolean;
  handleDataChange: (e: any) => void;
  onSubmit: (e: any) => void;
}

const Block: React.FC<Props> = ({
  block,
  nonce,
  data,
  previous,
  hashText,
  isSubmitting,
  handleDataChange,
  onSubmit,
}) => {
  return (
    <Card className={cardClass(hashText)}>
      <Card.Body>
        <Form>
          <Form.Group as={Row} controlId="formHorizontalBlock">
            <Form.Label column sm={2}>
              Block
            </Form.Label>
            <Col sm={10}>
              <InputGroup>
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">#</InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl
                  aria-describedby="basic-addon1"
                  name="block"
                  value={block}
                  onChange={handleDataChange}
                />
              </InputGroup>
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formHorizontalNonce">
            <Form.Label column sm={2}>
              Nonce
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="nonce"
                value={nonce}
                onChange={handleDataChange}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formHorizontalData">
            <Form.Label column sm={2}>
              Data
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                as="textarea"
                name="data"
                rows={10}
                value={data}
                onChange={handleDataChange}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formHorizontalPrev">
            <Form.Label column sm={2}>
              Prev
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                name="previous"
                placeholder="Prev"
                value={previous}
                disabled
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formHorizontalHash">
            <Form.Label column sm={2}>
              Hash
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="Hash"
                value={hashText}
                disabled
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3">
            <Form.Label column sm={2}></Form.Label>
            <Col sm={10}>
              <Button variant="primary" onClick={onSubmit}>
                Mine
                {isSubmitting && (
                  <FontAwesomeIcon icon="spinner" spin className="ml-1" />
                )}
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default Block;
