import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import {
  SHA256,
  cardClass,
  maximumNonce,
  difficulty,
  difficultyPattern,
} from "../../utils";

const BlockPage: React.FC = () => {
  const [block, setBlock] = useState<any>(1);
  const [nonce, setNonce] = useState<any>(72608);
  const [hashData, setHashData] = useState("");
  const [hashText, setHashText] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDataChange = (event: any) => {
    const value = event && event.target ? event.target.value : event;
    setHashData(value);
    setHashText(SHA256(getText()));
  };

  const onMineClick = (e: any) => {
    e.preventDefault();
    setIsSubmitting(true);
    for (var x = 0; x <= maximumNonce; x++) {
      const newNonce = `${block}${x}${hashData}`;
      const hashValue = SHA256(newNonce);
      if (hashValue.substr(0, difficulty) === difficultyPattern()) {
        setHashText(hashValue);
        setNonce(x);
        break;
      }
    }

    setTimeout(function () {
      setIsSubmitting(false);
    }, 250);
  };

  const getText = useCallback(() => {
    return `${block}${nonce}${hashData}`;
  }, [block, nonce, hashData]);

  useEffect(() => {
    setHashText(SHA256(getText()));
  }, [getText]);

  return (
    <Container>
      <Row>
        <Col xl={12}>
          <h1>Block</h1>
          <Card className={cardClass(hashText)}>
            <Card.Body>
              <Form>
                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalBlock"
                >
                  <Form.Label column sm={2}>
                    Block
                  </Form.Label>
                  <Col sm={10}>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">#</InputGroup.Text>
                      </InputGroup.Prepend>
                      <FormControl
                        aria-describedby="basic-addon1"
                        value={block}
                        onChange={(e) => setBlock(e.target.value)}
                      />
                    </InputGroup>
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalNonce"
                >
                  <Form.Label column sm={2}>
                    Nonce
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      value={nonce}
                      onChange={(e) => setNonce(e.target.value)}
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalData"
                >
                  <Form.Label column sm={2}>
                    Data
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      as="textarea"
                      rows={10}
                      value={hashData}
                      onChange={handleDataChange}
                    />
                  </Col>
                </Form.Group>

                <Form.Group
                  as={Row}
                  className="mb-3"
                  controlId="formHorizontalHash"
                >
                  <Form.Label column sm={2}>
                    Hash
                  </Form.Label>
                  <Col sm={10}>
                    <Form.Control
                      type="text"
                      placeholder="Hash"
                      disabled
                      value={hashText}
                    />
                  </Col>
                </Form.Group>
                <Form.Group as={Row} className="mb-3">
                  <Form.Label column sm={2}></Form.Label>
                  <Col sm={10}>
                    <Button variant="primary" onClick={onMineClick}>
                      Mine
                      {isSubmitting && (
                        <FontAwesomeIcon icon="spinner" spin className="ml-1" />
                      )}
                    </Button>
                  </Col>
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default BlockPage;
