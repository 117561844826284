import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Block from "../../components/Block/Block";
import { BLOCKCHAIN_BLOCKS } from "../../constants/blockchain";
import {
  difficultyPattern,
  maximumNonce,
  SHA256,
  difficulty,
} from "../../utils";

const Blockchain: React.FC = () => {
  const [hashBlocks, setHashBlocks] = useState<any>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeIndex, setActiveIndex] = useState<any>(null);

  const handleDataChange = (event: any, index: any) => {
    console.log("handleDataChange");
    const value = event && event.target ? event.target.value : event;
    const key = event.target.name;

    let prevState = [...hashBlocks];
    prevState[index] = {
      ...prevState[index],
      [key]: value,
    };

    setHashBlocks(updateChain(prevState));
  };

  const updateChain = (prevState: any) => {
    prevState.forEach((item: any, idx: number) => {
      if (idx > 0) {
        const block = prevState[idx - 1];
        const hash = updateHash(item.block, item.nonce, item.data, block.hash);
        item.previous = block.hash;
        item.hash = hash;
      } else {
        const hash = updateHash(
          item.block,
          item.nonce,
          item.data,
          item.previous
        );
        item.hash = hash;
      }
    });
    return prevState;
  };

  const updateHash = (block: any, nonce: any, data: any, previous: any) => {
    return SHA256(
      `${String(block)}${String(nonce)}${String(data)}${String(previous)}`
    );
  };

  const onMineClick = (e: any, index: number) => {
    e.preventDefault();
    setIsSubmitting(true);
    setActiveIndex(index);
    let prevState = [...hashBlocks];
    const item = prevState[index];

    for (var x = 0; x <= maximumNonce; x++) {
      const hashValue = updateHash(item.block, x, item.data, item.previous);
      if (hashValue.substr(0, difficulty) === difficultyPattern()) {
        prevState[index] = {
          ...prevState[index],
          nonce: x,
          hash: hashValue,
        };
        setHashBlocks(updateChain(prevState));
        break;
      }
    }

    setTimeout(function () {
      setIsSubmitting(false);
      setActiveIndex(null);
    }, 250);
  };

  useEffect(() => {
    const newBlocks = BLOCKCHAIN_BLOCKS.map((item) => {
      const data = "";
      const hash = updateHash(item.block, item.nonce, data, item.previous);
      return {
        ...item,
        data,
        hash,
        isSubmitting: false,
      };
    });
    setHashBlocks(newBlocks);
  }, []);

  return (
    <Container fluid>
      <h1>Blockchain</h1>
      <Row className="row-horizon flex-row">
        {hashBlocks.map((block: any, index: number) => {
          return (
            <Col xs={5} className="mb-3" key={index}>
              <Block
                block={block.block}
                chain={block.chain}
                nonce={block.nonce}
                previous={block.previous}
                data={block.data}
                hashText={block.hash}
                isSubmitting={isSubmitting && activeIndex === index}
                handleDataChange={(e) => handleDataChange(e, index)}
                onSubmit={(e) => onMineClick(e, index)}
              />
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default Blockchain;
