import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const NavBarHeader: React.FC = () => {
  const location = useLocation();
  return (
    <Navbar className="mb-4" bg="dark" variant="dark" expand="lg">
      <Container>
        <Navbar.Brand href="/">Blockchain Demo</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <div className="mr-auto"></div>
          <Nav activeKey={location.pathname}>
            <Nav.Link href="/hash">Hash</Nav.Link>
            <Nav.Link href="/block">Block</Nav.Link>
            <Nav.Link href="/blockchain">Blockchain</Nav.Link>
            <Nav.Link href="/distributed">Distributed</Nav.Link>
            <Nav.Link href="/tokens">Tokens</Nav.Link>
            <Nav.Link href="/coinbase">Coinbase</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBarHeader;
