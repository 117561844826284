import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import CoinbaseBlock from "../../components/CoinbaseBlock/CoinbaseBlock";
import {
  BLOCKCHAIN_COINBASE_BLOCKS,
  BLOCKCHAIN_PEERS,
} from "../../constants/blockchain";
import {
  difficulty,
  difficultyPattern,
  maximumNonce,
  SHA256,
} from "../../utils";

const Coinbase: React.FC = () => {
  const [hashBlocks, setHashBlocks] = useState<any>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeIndex, setActiveIndex] = useState<any>(null);

  const handleDataChange = (event: any, chain: number, index: number) => {
    const value = event && event.target ? event.target.value : event;
    const key = event.target.name;

    let prevState = [...hashBlocks];
    let prevBlock = prevState[chain].blocks;
    prevBlock[index] = {
      ...prevBlock[index],
      [key]: value,
    };

    prevState[chain] = {
      ...prevState[chain],
      blocks: prevBlock,
    };

    setHashBlocks(updateChain(prevState, chain));
  };

  const handleTXChange = (
    event: any,
    chain: number,
    index: number,
    idx: number
  ) => {
    const value = event && event.target ? event.target.value : event;
    const key = event.target.name;

    let prevState = [...hashBlocks];
    let prevBlock = prevState[chain].blocks;
    let prevTxs = prevState[chain].blocks[index].txs;

    prevTxs[idx] = {
      ...prevTxs[idx],
      [key]: value,
    };

    prevBlock[index] = {
      ...prevBlock[index],
      txs: prevTxs,
    };

    prevState[chain] = {
      ...prevState[chain],
      blocks: prevBlock,
    };

    setHashBlocks(updateChain(prevState, chain));
  };

  const updateChain = (prevState: any, chain: number) => {
    prevState.forEach((item: any, index: number) => {
      let prevBlocks = item.blocks;
      prevBlocks.forEach((block: any, idx: number) => {
        if (chain === index) {
          if (idx > 0) {
            const prev = prevBlocks[idx - 1];
            const hash = updateHash(
              block.block,
              block.nonce,
              block.coinbasevalue,
              block.coinbaseto,
              block.txs,
              prev.hash
            );
            block.previous = prev.hash;
            block.hash = hash;
          } else {
            const hash = updateHash(
              block.block,
              block.nonce,
              block.coinbasevalue,
              block.coinbaseto,
              block.txs,
              block.previous
            );
            block.hash = hash;
          }
        } else {
          const hash = updateHash(
            block.block,
            block.nonce,
            block.coinbasevalue,
            block.coinbaseto,
            block.txs,
            block.previous
          );
          block.hash = hash;
        }
      });
    });
    return prevState;
  };

  const updateHash = (
    block: any,
    nonce: any,
    coinbasevalue: any,
    coinbaseto: any,
    txs: any,
    previous: any
  ) => {
    let sHash = `${String(block)}${String(nonce)}${coinbasevalue}${coinbaseto}`;
    for (let index = 0; index < txs.length; index++) {
      const element = txs[index];
      sHash = `${sHash}${element.value}${element.from}${element.to}`;
    }
    sHash = `${sHash}${String(previous)}`;
    return SHA256(sHash);
  };

  const onMineClick = (e: any, chain: number, index: number) => {
    e.preventDefault();
    setIsSubmitting(true);
    setActiveIndex(index);

    let prevState = [...hashBlocks];
    const item = prevState[chain];
    let prevBlocks = item.blocks;
    let prevBlock = prevBlocks[index];

    for (var x = 0; x <= maximumNonce; x++) {
      const hashValue = updateHash(
        prevBlock.block,
        x,
        prevBlock.coinbasevalue,
        prevBlock.coinbaseto,
        prevBlock.txs,
        prevBlock.previous
      );

      if (hashValue.substr(0, difficulty) === difficultyPattern()) {
        prevBlocks[index] = {
          ...prevBlocks[index],
          nonce: x,
          hash: hashValue,
        };

        prevState[chain] = {
          ...prevState[chain],
          blocks: prevBlocks,
        };
        setHashBlocks(updateChain(prevState, chain));
        break;
      }
    }

    setTimeout(function () {
      setIsSubmitting(false);
      setActiveIndex(null);
    }, 250);
  };

  useEffect(() => {
    const newPeers = BLOCKCHAIN_PEERS.map((peer) => {
      const newBlocks = BLOCKCHAIN_COINBASE_BLOCKS.map((item) => {
        const hash = updateHash(
          item.block,
          item.nonce,
          item.coinbasevalue,
          item.coinbaseto,
          item.txs,
          item.previous
        );
        return {
          ...item,
          hash,
          isSubmitting: false,
        };
      });
      return {
        ...peer,
        blocks: newBlocks,
      };
    });
    setHashBlocks(newPeers);
  }, []);

  return (
    <Container fluid>
      <h1>Coinbase Transactions</h1>
      {hashBlocks.map((peer: any, index: number) => {
        return (
          <div className="my-3" key={index}>
            <h3>Peer {peer.user}</h3>
            <Row className="row-horizon flex-row">
              {peer.blocks.map((block: any, index1: number) => {
                return (
                  <Col xs={5} className="mb-3" key={index1}>
                    <CoinbaseBlock
                      txs={block.txs}
                      block={block.block}
                      chain={index}
                      index={index1}
                      nonce={block.nonce}
                      coinbasevalue={block.coinbasevalue}
                      coinbaseto={block.coinbaseto}
                      previous={block.previous}
                      hashText={block.hash}
                      isSubmitting={isSubmitting && activeIndex === index1}
                      handleDataChange={(e) =>
                        handleDataChange(e, index, index1)
                      }
                      handleTXChange={handleTXChange}
                      onSubmit={(e) => onMineClick(e, index, index1)}
                    />
                  </Col>
                );
              })}
            </Row>
          </div>
        );
      })}
    </Container>
  );
};

export default Coinbase;
